const fields = {
  inputs: {
    affilate_name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "affilate_name",
        placeholder: "Название филиала (будет видно пользователям)",
        label: "Название филиала (будет видно пользователям)",
        type: "text",
        always_type: "text",
      },
    },
    affilate_id: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "affilate_id",
        placeholder: "id филиала (должен совпадать с id филиала в frontpad)",
        label: "id филиала (должен совпадать с id филиала в frontpad)",
        type: "text",
        always_type: "text",
      },
    },
    is_open: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "is_open",
        placeholder: "Статус работоспособности филиала",
        label: "Статус работоспособности филиала",
        type: "text",
        always_type: "text",
      },
    },
  },
  radios: {
    open: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "open",
        label: "Открыто",
      },
      errorBag: [],
      validate: {},
    },
    closed: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "closed",
        label: "Закрыто",
      },
      errorBag: [],
      validate: {},
    },
  },
  checkboxes: {
    delivery_access: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "delivery_access",
        label: "Осуществляется доставка",
      },
      errorBag: [],
      validate: {},
    },
    pickup_access: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "pickup_access",
        label: "Осуществляется самовывоз",
      },
      errorBag: [],
      validate: {},
    },
  },
};

export { fields };
